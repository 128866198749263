import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Items from '../components/Items'
import Root from '../layouts/Root'
import config from '../config'

const Diensten = ({ data }) => (
  <Root>
    {/* prettier-ignore */}
    <Helmet>
      <title>{`Diensten | ${config.title}`}</title>
      <meta property="og:title" content={`Diensten | ${config.title}`} />
      <meta name="description" content="Wij verzorgen o.a.: Transportbeveiliging, Portofoonverhuur, Verkeersregelaars, Objectbeveiliging, Evenementenbeveiliging en Horecabeveiliging." />
      <meta property="og:description" content="Wij verzorgen o.a.: Transportbeveiliging, Portofoonverhuur, Verkeersregelaars, Objectbeveiliging, Evenementenbeveiliging en Horecabeveiliging." />
    </Helmet>

    <main>
      <h1 className="t-heading-l t-center">Diensten</h1>

      <Items data={data.diensten} maxTwo />
    </main>
  </Root>
)

export const query = graphql`
  query {
    diensten: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/diensten/.+/" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            cover_alt
            cover {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Diensten
